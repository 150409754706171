export enum MODAL_TYPES {
  OPEN = 'MODAL_TYPES/OPEN',
  CLOSE = 'MODAL_TYPES/CLOSE',
}

export interface OpenModalAction {
  type: MODAL_TYPES.OPEN;
  payload: {};
}

export interface CloseModalAction {
  type: MODAL_TYPES.CLOSE;
  payload: {};
}

export interface ModalState {
  isOpen: boolean;
}

export type ModalActions = OpenModalAction | CloseModalAction;
