import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { ConfigState } from '../../config/config.types';

interface LogoTitleSectionProps {
    config: ConfigState;
    hasError: boolean;
}

export const LogoTitleSection: React.FunctionComponent<LogoTitleSectionProps> = ({
    config,
    hasError,
}) => {
  return (
    <div className='logoTitleContainer'>
        <div className="brandLogoContainer">
            {config.brandImage && (
                <img
                    className="brandLogoContainer__brandLogo"
                    src={config.brandImage}
                    alt={`${config.label} Brand Logo`}
                />
            )}
        </div>
        <p className="logoTitleContainer__title">
            <Translate id={hasError ? "SelectAccount.tryAgain" : "SelectAccount.title"}/>
        </p>
        {!hasError && (
            <p className="logoTitleContainer__description">
                <Translate id="SelectAccount.description" data={{ DMO: config.label }} />
            </p>
        )}
    </div>
  )
}
