import { connect } from 'react-redux';
import { AppState } from '../../types';
import { ErrorComponent } from '../Error.component';

const mapStateToProps = (state: AppState, ownProps: { match: { url: string } }) => ({
  config: state.config,
  hasConfig: state.config.id !== '',
  tryAgainUrl: `${ownProps.match.url.replace(/\/$/, '')}/auth`,
});

export const ErrorConnect = connect(mapStateToProps)(ErrorComponent);
