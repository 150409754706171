import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { startLoader } from '../../components/Loader/deps/loader.actions';
import { isLoading } from '../../components/Loader/deps/loader.selectors';
import { LOADER_IDS } from '../../components/Loader/deps/loader.types';
import { AppState } from '../../types';
import { Auth } from '../Auth.component';
import { authAccount } from './auth.actions';

const mapStateToProps = (state: AppState) => ({
  config: state.config,
  isLoading: isLoading(LOADER_IDS.CONFIG)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  authAccount: () => dispatch(authAccount()),
  startLoader: () => dispatch(startLoader(LOADER_IDS.CONFIG)),
});

export const AuthConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Auth);
