import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { REACT_APP_SERVER_URL } from '../../constants';
import { selectSlug } from '../../router/router.selectors';
import { AppActions, AppState } from '../../types';
import { AUTH_TYPES } from './auth.types';

export const facebookAuthEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(AUTH_TYPES.AUTHENTICATE_ACCOUNT),
    filter(() => {
      const slug = selectSlug(state$.value);
      window.location.href = `${REACT_APP_SERVER_URL}/api/auth/${slug}`;
      return false;
    }),
  );

export const authEpics = [facebookAuthEpic];
