import * as React from 'react';
import { Image, Title } from '../../components';

export interface HeaderProps {
  hideImg: boolean;
  imgAlt: string;
  imgFallbackText: string;
  imgSrc: string;
}

export const HeaderComponent: React.SFC<HeaderProps> = ({
  hideImg,
  imgSrc,
  imgAlt,
  imgFallbackText,
}) => (
  <React.Fragment>
    {hideImg ? (
      <Title>{imgFallbackText}</Title>
    ) : (
      <Image src={imgSrc} alt={imgAlt}>
        <Title>{imgFallbackText}</Title>
      </Image>
    )}
  </React.Fragment>
);
