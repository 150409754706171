import {
  ChangeEmailAction,
  ChangeEmailPayload,
  EMAIL_TYPES,
  EmailErrorAction,
  EmailErrorPayload,
  SendEmailAction,
  SendEmailPayload,
} from './email.types';

export const changeEmail = (inputValue: ChangeEmailPayload): ChangeEmailAction => ({
  type: EMAIL_TYPES.CHANGE_EMAIL,
  payload: inputValue,
});

export const sendEmail = (emailPayload: SendEmailPayload): SendEmailAction => ({
  type: EMAIL_TYPES.SEND_EMAIL,
  payload: emailPayload,
});

export const emailError = (errorPayload: EmailErrorPayload): EmailErrorAction => ({
  type: EMAIL_TYPES.EMAIL_ERROR,
  payload: errorPayload,
});
