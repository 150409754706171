import { CloseModalAction, MODAL_TYPES, OpenModalAction } from './modal.types';

export const openModal = (): OpenModalAction => ({
  type: MODAL_TYPES.OPEN,
  payload: {},
});

export const closeModal = (): CloseModalAction => ({
  type: MODAL_TYPES.CLOSE,
  payload: {},
});
