import * as React from 'react';
import './MessagePanel.scss';

export enum MESSAGE_PANEL_TYPES {
  DEFAULT = "default",
  ERROR = "error",
}

interface MessagePanelProps {
  type?: string;
  className?: string;
  children: any;
}

export const MessagePanel: React.FunctionComponent<MessagePanelProps> = ({
  type = MESSAGE_PANEL_TYPES.DEFAULT,
  className,
  children,
}) => {
  return (
    <div
      className={`messagePanel messagePanel--${type} ${className || ''}`}
    >
      {children}
    </div>
  )
}