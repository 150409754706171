import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../../types';
import { ModalComponent } from '../Modal.component';
import { closeModal } from './modal.actions';
import { isOpen } from './modal.selectors';

const mapStateToProps = (state: AppState) => ({
  isOpen: isOpen(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

export const Modal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalComponent);
