import * as React from 'react';
import { LoaderActions } from '../components/Loader/deps/loader.types';
import { ConfigState } from '../config/config.types';
import { LandingPage } from '.';
import { AuthAccountAction } from './deps/auth.types';
import { History, LocationState } from "history";

interface AuthProps {
  authAccount: () => AuthAccountAction;
  startLoader: () => LoaderActions;
  config: ConfigState;
  history: History<LocationState>;
  isLoading: boolean;
}

export class Auth extends React.Component<AuthProps> {
  componentDidMount() {
    if (this.props.config.id === '') {
      this.props.startLoader();
    }
  }
  render() {
    return (
      <div className="auth-component">
        <LandingPage callToAction={() => {this.props.history.push('./email')}} />
      </div>
    );
  }
}
