import React, { useEffect,useRef,useState } from 'react';
import { LoaderComponent } from '../components';

export default (Component: React.ComponentType) => ({ isLoading, loadApp, ...props }: any) => {
  const [initialized, setInitialized] = useState(false);
  const prevLoading = useRef();
  useEffect(() => {
    loadApp();
  },[]);
  useEffect(() => {
    if(prevLoading.current && !isLoading) setInitialized(true);
    prevLoading.current = isLoading;
  },[isLoading]);
  if(!initialized || isLoading) return <LoaderComponent size="medium" isLoading={isLoading} />;
  return <Component {...props} />
}