import { Button } from '@crowdriff/flock';
import * as React from 'react';

import './AttachedButton.scss';
interface AttachedButtonProps {
  color?: string;
  isDisabled?: boolean;
  className?: string;
  btnClassName?: string;
  onClick: () => void;
  children?: {};
}
export const AttachedButton: React.SFC<AttachedButtonProps> = ({
  color = "instagram",
  isDisabled = false,
  className = '',
  btnClassName = '',
  onClick,
  children,
}) => {
  return (
    <div className={`attached-button-component ${className || ''}`}>
      <Button className={btnClassName} isDisabled={isDisabled} color={color} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
};

export default AttachedButton;
