import isomorphicFetch from 'isomorphic-fetch';

/**
 * Fetch helpers!
 * Give them a url and an optional payload, and get back a promise of
 * gold and riches! (But probably just data from the server)
 */

export function get(url: string) {
  const promise = new Promise((resolve, reject) => {
    isomorphicFetch(url, {
      method: 'get',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(err => {
            reject(err);
          });
        }
        return res.json();
      })
      .then(body => resolve(body))
      .catch(error => reject(error));
  });

  return promise;
}

export function post(
  url: string,
  payload: {
    [key: string]: any;
  },
) {
  const promise = new Promise((resolve, reject) => {
    isomorphicFetch(url, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(err => {
            reject(err);
          });
        }
        return res.json();
      })
      .then(body => resolve(body))
      .catch(error => reject(error));
  });

  return promise;
}

export function put(
  url: string,
  payload: {
    [key: string]: any;
  },
) {
  const promise = new Promise((resolve, reject) => {
    isomorphicFetch(url, {
      method: 'put',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(err => {
            reject(err);
          });
        }
        return res.json();
      })
      .then(body => resolve(body))
      .catch(error => reject(error));
  });

  return promise;
}

export function remove(
  url: string,
  payload: {
    [key: string]: any;
  },
) {
  const promise = new Promise((resolve, reject) => {
    isomorphicFetch(url, {
      method: 'delete',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(err => {
            reject(err);
          });
        }
        return res.json();
      })
      .then(body => resolve(body))
      .catch(error => reject(error));
  });

  return promise;
}

/**
 * graphQl
 * This one is different! Instead of passing it a payload, make a
 * graphQl querystring and pass it in!
 */
export function graphQl(query: string, variables = {}) {
  const promise = new Promise((resolve, reject) => {
    isomorphicFetch('/api/graphql', {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ query, variables }),
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(err => {
            reject(err);
          });
        }
        return res.json();
      })
      .then(body => resolve(body))
      .catch(error => reject(error));
  });
  return promise;
}

export const to = (promise: Promise<any>) => promise.then(data => [null, data]).catch(err => [err]);
