import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { AttachedButton, BodyText, Card, Header } from '../components';
import './Error.scss';

interface ErrorComponentProps {
  onClickTryAgain: () => {};
  match: {
    url: string;
  };
  hasConfig: boolean;
  tryAgainUrl: string;
}
export class ErrorComponent extends React.Component<ErrorComponentProps> {
  render() {
    const { onClickTryAgain, hasConfig, tryAgainUrl } = this.props;
    return (
      <div className="auth-portal auth-portal__error-message">
        <Card>
          {hasConfig && <Header />}
          <BodyText>
            <Translate id="errors.somethingWrong" />
          </BodyText>
          {hasConfig && (
            <Link to={tryAgainUrl}>
              <AttachedButton onClick={onClickTryAgain}>
                <Translate id="general.tryAgain" />
              </AttachedButton>
            </Link>
          )}
        </Card>
      </div>
    );
  }
}
