import { RouterAction, RouterState } from 'connected-react-router';
import { LocalizeState } from 'react-localize-redux';
import { LoadAppActions } from './app/deps/App.types';
import { AuthActions, AuthState } from './auth/deps/auth.types';
import { LoaderActions, LoaderState} from './components/Loader/deps/loader.types';
import { ModalActions, ModalState } from './components/Modal/deps/modal.types';
import { ConfigState, GetConfigActions } from './config/config.types';
import { EmailActions, EmailState } from './email/deps/email.types';
import { SelectAccountActions, SelectAccountState} from './select-account/deps/SelectAccount.types';

export enum ROUTES {
  ROOT = '/',
  DEFAULT = '/app',

  SLUG = '/app/:slug',
  AUTH = '/app/:slug/auth',
  DONE = '/app/:slug/done',
  EMAIL = '/app/:slug/email',
  PERMISSIONS_INFO = '/app/:slug/permissions-info',
  SELECT_ACCOUNT = '/app/:slug/select-account',

  // to be removed with associated components
  COMPONENT_DEMO = '/app/:slug/component',
  NOT_BUSINESS = '/app/:slug/not-a-business',
}

export interface AppState {
  auth: AuthState;
  email: EmailState;
  loader: LoaderState;
  localize: LocalizeState;
  modal: ModalState;
  router: RouterState;
  config: ConfigState;
  selectAccount: SelectAccountState;
}

  export type AppActions =
  | AuthActions
  | GetConfigActions
  | LoaderActions
  | LoadAppActions
  | ModalActions
  | RouterAction
  | SelectAccountActions
  | EmailActions;
