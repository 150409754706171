import { Button, Icon } from '@crowdriff/flock';
import * as React from 'react';

import './Modal.scss';

export interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  wrapperRef?: React.RefObject<HTMLDivElement>;
}

export const ModalComponent: React.SFC<ModalProps> = ({ children, closeModal, isOpen, wrapperRef }) => {
 return isOpen ? (
    <div className="modalWrapper">
      <div className="modal" ref={wrapperRef}>
        <Button
          color="grey"
          className="modal__close"
          onClick={closeModal}
          buttonStyle="link"
        >
          <Icon type="close" />
        </Button>
        <div className="modal__children">{children}</div>
      </div>
    </div>
  ) : null;
}
