import { CallHistoryMethodAction, push } from 'connected-react-router';
import replace from 'ramda/es/replace';
import { matchPath } from 'react-router-dom';
import { AppState, ROUTES } from '../types';
import { getRoute } from './router.selectors';

export const pushWithStateParams = (state: AppState, route: ROUTES): CallHistoryMethodAction => {
  const { params } = matchPath(state.router.location.pathname, getRoute(state)) || { params: {} };
  const path = Object.keys(params).reduce((acc, param) => {
    return replace(`:${param}`, params[param], acc);
  }, route);
  return push(path);
};
