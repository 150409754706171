import '@crowdriff/flock/lib/main.css';
import * as Sentry from '@sentry/browser';
import { ConnectedRouter } from 'connected-react-router';
import 'normalize.css';
import * as React from 'react';
import { render } from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import 'url-search-params-polyfill';
import { ErrorBoundary } from './components';
import * as serviceWorker from './serviceWorker';
import { history, store } from './store';
import './styles/app.scss';
import { ROUTES } from './types';
import { App } from './app';
import { renderLoaderConnect } from './HoC';

const env = process.env.NODE_ENV || 'development';
if (env === 'production') {
  Sentry.init({ dsn: 'https://1f7746941025465cbbbbd9c11f7e4646@sentry.io/1369811' });
}

render(
  <ErrorBoundary>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path={ROUTES.DEFAULT} component={renderLoaderConnect(App) as React.ComponentType} />
            </Switch>
          </ConnectedRouter>
        </BrowserRouter>
      </LocalizeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
