import { connect } from 'react-redux';
import { AppState } from '../../../types';
import { LandingPage } from '../LandingPage.component';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state: AppState) => ({
  config: state.config,
  translate: getTranslate(state.localize),
  isLoadingConfig: state.loader.CONFIG.isLoading,
});

export const LandingPageConnect = connect(
  mapStateToProps,
)(LandingPage);
