import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../types';
import { Email } from '../Email.component';
import { changeEmail } from './email.actions';
import { DispatchProps, StateProps } from './email.types';
import { getTranslate } from 'react-localize-redux';
import { openModal } from '../../components/Modal/deps/modal.actions';
import { closeModal } from '../../components/Modal/deps/modal.actions';
import { history } from '../../store';

export const mapStateToProps = (state: AppState): StateProps => ({
  email: state.email.email,
  isLoading: state.loader.EMAIL.isLoading,
  config: state.config,
  translate: getTranslate(state.localize),
  errorMsg: state.email.errorMsg,
  state,
});
export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  changeEmail: (val: string) => dispatch(changeEmail({ email: val })),
  openModal: () => dispatch(openModal()),
  closeModal: () => dispatch(closeModal()),
});

export const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
) => ({
  email: stateProps.email,
  errorMsg: stateProps.errorMsg,
  config: stateProps.config,
  translate: stateProps.translate,
  changeEmail: dispatchProps.changeEmail,
  openModal: dispatchProps.openModal,
  closeModal: dispatchProps.closeModal,
  history,
});

export const EmailConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Email);
