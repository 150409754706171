import * as React from 'react';
import { Translate } from 'react-localize-redux';
import AttachedButton from '../AttachedButton/AttachedButton';
import BodyText from '../BodyText/BodyText';
import Card from '../Card/Card';
import Image from '../Image/Image';
import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';

import './componentDemo.scss';

export default class ComponentDemo extends React.Component {
  render() {
    return (
      <div className="auth-portal component-demo">
        <Card>
          <Image src="http://placehold.it/300/400" alt="fallback">
            This is a fallback
          </Image>
          <Title>
            <Translate id="general.test" />
          </Title>
          <Subtitle>
            <p>Title</p>
          </Subtitle>
          <BodyText>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sagittis sagittis purus
              in bibendum. Phasellus urna nulla, rhoncus id ultrices non, bibendum non arcu. Proin
              magna justo, semper eu velit eu, scelerisque ultrices orci. Quisque accumsan, lacus et
              vestibulum suscipit, arcu ex ornare nunc, a convallis nibh nunc id odio. Curabitur
              nisl felis, convallis vitae vehicula quis, ultrices eget eros. Ut nec hendrerit velit.
              Nullam scelerisque quam imperdiet mauris consectetur, ut posuere massa viverra. Aenean
              aliquam auctor nunc consequat hendrerit. In eu condimentum risus, ac semper nisi. Duis
              ultrices tristique mauris. In eget tempus urna, vitae pharetra lectus. Maecenas varius
              turpis lacus, sed facilisis ante elementum a. Maecenas dictum enim a quam euismod
              efficitur. Nunc nisi enim, eleifend nec nunc ut, vestibulum efficitur mauris. Aenean
              porta turpis nec lectus efficitur aliquam. Cras quis purus nec nulla rutrum maximus eu
              vitae ligula.
            </p>
          </BodyText>
          <AttachedButton
            onClick={() => {
              console.log('clicked!');
            }}
          >
            This is a button
          </AttachedButton>
        </Card>
        <Card>
          <Title size={35}>Title</Title>
          <BodyText className="test-class">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sagittis sagittis purus
              in bibendum. Phasellus urna nulla, rhoncus id ultrices non, bibendum non arcu. Proin
              magna justo, semper eu velit eu, scelerisque ultrices orci. Quisque accumsan, lacus et
              vestibulum suscipit, arcu ex ornare nunc, a convallis nibh nunc id odio. Curabitur
              nisl felis, convallis vitae vehicula quis, ultrices eget eros. Ut nec hendrerit velit.
              Nullam scelerisque quam imperdiet mauris consectetur, ut posuere massa viverra. Aenean
              aliquam auctor nunc consequat hendrerit. In eu condimentum risus, ac semper nisi. Duis
              ultrices tristique mauris. In eget tempus urna, vitae pharetra lectus. Maecenas varius
              turpis lacus, sed facilisis ante elementum a. Maecenas dictum enim a quam euismod
              efficitur. Nunc nisi enim, eleifend nec nunc ut, vestibulum efficitur mauris. Aenean
              porta turpis nec lectus efficitur aliquam. Cras quis purus nec nulla rutrum maximus eu
              vitae ligula.
            </p>
          </BodyText>
          <BodyText>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sagittis sagittis purus
              in bibendum. Phasellus urna nulla, rhoncus id ultrices non, bibendum non arcu. Proin
              magna justo, semper eu velit eu, scelerisque ultrices orci. Quisque accumsan, lacus et
              vestibulum suscipit, arcu ex ornare nunc, a convallis nibh nunc id odio. Curabitur
              nisl felis, convallis vitae vehicula quis, ultrices eget eros. Ut nec hendrerit velit.
              Nullam scelerisque quam imperdiet mauris consectetur, ut posuere massa viverra. Aenean
              aliquam auctor nunc consequat hendrerit. In eu condimentum risus, ac semper nisi. Duis
              ultrices tristique mauris. In eget tempus urna, vitae pharetra lectus. Maecenas varius
              turpis lacus, sed facilisis ante elementum a. Maecenas dictum enim a quam euismod
              efficitur. Nunc nisi enim, eleifend nec nunc ut, vestibulum efficitur mauris. Aenean
              porta turpis nec lectus efficitur aliquam. Cras quis purus nec nulla rutrum maximus eu
              vitae ligula.
            </p>
          </BodyText>
        </Card>
      </div>
    );
  }
}
