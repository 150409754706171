import * as React from 'react';
import './Title.scss';
interface TitleProps {
  children: {};
  className?: string;
  size?: number;
}
export const Title: React.SFC<TitleProps> = ({ children, className, size }) => {
  const titleStyle = {
    fontSize: size,
  };
  return (
    <div className={`title-component ${className ? className : ''}`} style={titleStyle}>
      {children}
    </div>
  );
};

export default Title;
