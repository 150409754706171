import { Icon, ExpansionPanel } from '@crowdriff/flock';
import React, { FunctionComponent } from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { ConfigState } from '../../config/config.types';
import { CTA } from '../../components/CTA/CTA.component';
import './LandingPage.scss';

interface LandingPageProps {
  config: ConfigState;
  callToAction: () => void;
  translate: TranslateFunction;
  galleryHash?: string;
  isLoadingConfig: boolean;
}

export const LandingPage: FunctionComponent<LandingPageProps> = ({
  config,
  callToAction,
  translate,
  isLoadingConfig
}) => {
  return (
    <div>
      <div className="brandLogoContainer">
        {config.brandImage && (
          <img
            className="brandLogoContainer__brandLogo"
            src={config.brandImage}
            alt={`${config.label} Brand Logo`}
          />
        )}
      </div>
      <h1 className="title">
        <Translate id="landingPage.title" />
      </h1>
      <p className="description">
        <Translate id="landingPage.description" data={{ DMO: config.label }} />
      </p>
      <section>
        <p>
          <Translate id="landingPage.subTitle" />
        </p>
        <div className="benefit">
          <Icon
            type="clock-thin"
            color="black"
            size={24}
            className="benefit__icon"
          />
          <p>
            <Translate id="landingPage.benefit1" />
          </p>
        </div>
        <div className="benefit">
          <Icon
            type="star-bubble"
            color="black"
            size={24}
            className="benefit__icon"
          />
          <p>
            <Translate id="landingPage.benefit2" />
          </p>
        </div>
        <div className="benefit">
          <Icon
            type="megaphone-outline"
            color="black"
            size={24}
            className="benefit__icon"
          />
          <p>
            <Translate id="landingPage.benefit3" />
          </p>
        </div>
      </section>
      <div className="callToActionContainer">
        <CTA
          callToAction={callToAction}
          callToActionLabel={translate("landingPage.cta")}
          brandColor={config.brandColor}
        />
      </div>
      <section className="faq">
        <ExpansionPanel
          className="faq__expansionPanel"
          label={translate('landingPage.FAQ1')}
          collapseIconType="minus"
        >
          <p>
            <Translate id="landingPage.FAQ1-1" />
          </p>
          <ul>
            <p>
              <li>
                <Translate id="landingPage.FAQ1-2" />
              </li>
            </p>
            <p>
              <li>
                <Translate id="landingPage.FAQ1-3" />
              </li>
            </p>
          </ul>
          <p>
            <Translate id="landingPage.FAQ1-4" />
          </p>
        </ExpansionPanel>
        <ExpansionPanel
          className="faq__expansionPanel"
          label={translate('landingPage.FAQ2')}
          collapseIconType="minus"
        >
          <p>
            <Translate id="landingPage.FAQ2-1" />
          </p>
        </ExpansionPanel>
      </section>
      <Translate id="landingPage.supportEmail" />
    </div>
  );
};
