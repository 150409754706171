import { AppActions } from '../../../types';
import { MODAL_TYPES, ModalState } from './modal.types';

const initialState: ModalState = {
  isOpen: false,
};

export const modalReducer = (state: ModalState = initialState, action: AppActions) => {
  switch (action.type) {
    case MODAL_TYPES.OPEN:
      return {
        isOpen: true,
      };
    case MODAL_TYPES.CLOSE:
      return {
        isOpen: false,
      };
    default:
      return state;
  }
};
