import { Button, Icon } from '@crowdriff/flock';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Card, Header, Subtitle, Title } from '../components';
import './NotBusiness.scss';

interface NotBusinessProps {
  children: {};
}

export const NotBusiness: React.SFC<NotBusinessProps> = () => {
  return (
    <div className="auth-portal auth-portal__not-business">
      <Card>
        <Header />
        <Title>
          <Translate id="notBusiness.looksLikeYourBusinessAccountIsNot" />
        </Title>
        <Subtitle>
          <Translate id="notBusiness.inOrderToContinueYouWill" />
        </Subtitle>
        <Button buttonStyle="fill" className="blue" iconPosition="right">
          <Translate id="notBusiness.findOutHowToConvertYourAccount" />
          <Icon type="angle-right" color="white" />
        </Button>
        <Button buttonStyle="outline" className="blue" iconPosition="right">
          <Translate id="notBusiness.iConvertedMyAccount" />
          <Icon type="reload" color="blue" />
        </Button>
      </Card>
    </div>
  );
};
