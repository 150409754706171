import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { Card, Title } from '..';

interface ErrorBoundaryProps {
  children: any;
}
const initialState = {
  error: null,
};
type ErrorState = Readonly<typeof initialState>;

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: ErrorState = initialState;
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }
  componentDidCatch(error: Error, errorInfo: { [key: string]: any }) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <div>
          <Card>
            <Title>Something went wrong!</Title>
            <a className="blue button" href="/">
              Click here to reload
            </a>
            <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
          </Card>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
