import { connect } from 'react-redux';
import { HIDE_HEADER_IMG_SLUGS } from '../../../constants';
import { AppState } from '../../../types';
import { HeaderComponent } from '../Header.component';

const mapStateToProps = (state: AppState) => ({
  imgSrc: state.config.brandImage,
  imgAlt: state.config.label,
  hideImg: HIDE_HEADER_IMG_SLUGS.includes(state.config.slug),
  imgFallbackText: state.config.label,
});

export const Header = connect(mapStateToProps)(HeaderComponent);
