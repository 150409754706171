import React from 'react';
import { Gifficon, GIFFICON_SIZES } from '../Gifficon/Gifficon.component';

import './Loader.scss';
interface LoaderProps {
  size: GIFFICON_SIZES;
  isLoading?: boolean;
}

export const LoaderComponent: React.FunctionComponent<LoaderProps> = ({
  isLoading,
  size,
}) => {
  const [showStatusLink, setShowStatusLink] = React.useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowStatusLink(true);
    }, 30000);
    return (): void => clearTimeout(timer);
  });
  return (
    <div className="test" style={{display: 'contents'}}>
      {isLoading && (
        <div key="loadingSpinner" className="loadingSpinner">
          <Gifficon type="van" size={size} altText="A loading spinner" />

          {/* The text is hardcoded in case there is an error with loading copy */}
          {showStatusLink && (
            <div className="loadingSpinner__statusLink">
              We appear to be having some issues. Check
              <a
                href="https://status.crowdriff.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                status.crowdriff.com
              </a>
              for more information
            </div>
          )}
        </div>
      )}
      </div>
  );
};
