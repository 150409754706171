import { AppActions } from '../../types';
import {
  GET_ACCOUNT_INFO_TYPES,
  SELECT_ACCOUNT_TYPES,
  SelectAccountState,
} from './SelectAccount.types';
import { getSelectedKeys } from './SelectAccount.utils';

const initalState = {
  errors: [],
  facebookName: '',
  facebookHref: '',
  facebookAvatar: '',
  hasSubmittedSearch: false,
  instagramAccounts: [],
  facebookPages: [],
  selectedAccounts: [],
  userToken: '',
};

export const selectAccountReducer = (
  state: SelectAccountState = initalState,
  action: AppActions,
): SelectAccountState => {
  switch (action.type) {
    case GET_ACCOUNT_INFO_TYPES.START:
      return initalState;
    case GET_ACCOUNT_INFO_TYPES.ERROR:
      return {
        ...state,
        hasSubmittedSearch: true,
        errors: action.payload.errors,
      };
    case GET_ACCOUNT_INFO_TYPES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        errors: [],
        hasSubmittedSearch: true,
      };
    case SELECT_ACCOUNT_TYPES.SUBMIT:
      return {
        ...state,
        selectedAccounts: getSelectedKeys(action.payload.selectedInstagramAccounts),
      };
    default:
      return state;
  }
};
