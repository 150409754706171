import React from 'react';
import './SidePanel.scss';

export interface SidePanelProps {
  route: string;
  hash: string;
}

const renderSidePanel = (route: string, hash: string) => {
  switch (route) {
    case 'permissions-info':
      return (
        <span className="sidePanel__authPreview">
          <img
            src="/img/facebook-modal-preview.svg"
            alt="Facebook authentication preview"
          />
        </span>
      )
    default:
      return (
        <React.Fragment>
          <div id={`cr__${hash}`} />
          <div className="sidePanel__poweredBy">
            <span>Powered By</span>
            <img
              src="/img/crowdriff-logo-blue-grey.svg"
              alt="Powered By Crowdriff"
            />
          </div>
        </React.Fragment>
      )
  }
}

export const SidePanel = ({route, hash}: SidePanelProps): React.ReactElement => {
  return (
    <div className='sidePanel'>
      {renderSidePanel(route, hash)}
    </div>
  )
}
