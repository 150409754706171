import * as Sentry from '@sentry/browser';
import { renderToStaticMarkup } from 'react-dom/server';
import { onMissingTranslationFunction } from 'react-localize-redux';
import en from '../languages/en';

const onMissingTranslation: onMissingTranslationFunction = ({ translationId, languageCode }) => {
  if (process.env.NODE_ENV === 'development') {
    return `missing translation key: ${translationId} for ${languageCode}`;
  }
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureEvent({
      message: `missing translation key: ${translationId} for ${languageCode}`,
      level: Sentry.Severity.Error,
      extra: {
        translationId,
        languageCode,
      },
    });
  }
  return '';
};

export const getLanguage = (navigator: any): string => {
  if (!navigator) return 'en';
  const languageCode = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage;
  if (!languageCode) return 'en';
  return languageCode.split('-')[0];
};

export const initializePayload = (language: string) => {
  const supportedLanguages = ['en', 'fr'];
  const defaultLanguage = supportedLanguages.indexOf(language) !== -1 ? language : 'en';
  return {
    languages: [{ name: 'English', code: 'en' }, { name: 'French', code: 'fr' }],
    translation: en,
    options: {
      defaultLanguage,
      renderToStaticMarkup,
      renderInnerHtml: true,
      ignoreTranslateChildren: true,
      onMissingTranslation,
    },
  };
};
