import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { appLoaded } from './App.actions';

import { AppState } from '../../types';
import { App } from '../App.component';

const mapStateToProps = (state: AppState) => {
  return {
    config: state.config,
    isLoading: state.loader.CONFIG.isLoading,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  appLoaded: () => dispatch(appLoaded()),
});
const appWithLocalize = withLocalize(App);
export const AppConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(appWithLocalize);
