import * as React from 'react';
import { Checkbox, Icon } from '@crowdriff/flock';
import { Translate } from 'react-localize-redux';
import { SelectedIDsMap } from '../deps/SelectAccount.types';

interface AccountSelectorProps {
  titleKey: string,
  accounts: { id: string, label: string }[],
  selectedAccounts: SelectedIDsMap,
  onChange: (id: string) => void,
  iconType: string;
}


export const AccountSelector: React.FunctionComponent<AccountSelectorProps> = ({
  titleKey,
  accounts,
  selectedAccounts,
  onChange,
  iconType
}) => {
  return (
    <>
      {accounts.length > 0 && (
        <div className="connectAccounts__accountList">
          <div className="connectAccounts__accountList--title connectAccounts__accountList--fullWidth">
            <Icon
              type={iconType}
              color="#666"
              size={14}
              className="connectAccounts__accountList--icon"
            />
            <Translate
              id={titleKey}
              data={{ plural: accounts.length > 1 ? 's' : '' }}
            />
          </div>
          <div className="connectAccounts__content connectAccounts__accountList--fullWidth">
            {accounts.map(account => (
              <Checkbox
                name={account.id}
                value={selectedAccounts[account.id] || false}
                onChange={() => onChange(account.id)}
                key={account.id}
              >
                <span>{account.label || ''}</span>
              </Checkbox>
            ))}
          </div>
        </div>
      )}
    </>
  )
}