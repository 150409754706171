import { Button } from '@crowdriff/flock';
import React, { FunctionComponent } from 'react';
import './CTA.scss';

interface LandingPageProps {
  callToAction?: () => void;
  callToActionLabel?: string | Object;
  isDisabled?: boolean;
  brandColor: string;
}

export const CTA: FunctionComponent<LandingPageProps> = ({
  callToAction,
  callToActionLabel,
  brandColor,
  isDisabled,
}) => {

  return (
    <div className="CTA">
      <Button
        style={{
          backgroundColor: brandColor,
          borderColor: brandColor,
        }}
        buttonStyle="fill"
        onClick={callToAction}
        className="CTA__callToAction"
        isDisabled={isDisabled}
      >
        {callToActionLabel}
      </Button>
      <div className="CTA__bottomBar">
        <Button
          style={{
            backgroundColor: brandColor,
            borderColor: brandColor,
          }}
          buttonStyle="fill"
          className="BottomBarCTA"
          onClick={callToAction}
          isDisabled={isDisabled}
        >
          {callToActionLabel}
        </Button>
      </div>
    </div>
  );
};
