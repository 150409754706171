import toPairs from 'ramda/es/toPairs';
import { SelectedIDsMap } from '../deps/SelectAccount.types';

export const getSelectedKeys = (obj: SelectedIDsMap = {}) => {
  return toPairs(obj)
    .filter(([id, val]) => val)
    .map(([id, val]) => id);
};

export const buildAllSelectedMap = (accounts: { id: string }[]): SelectedIDsMap => {
  const selectedMap: {
    [id: string]: boolean;
  } = {};
  accounts.forEach((account) => {
    selectedMap[account.id] = true;
  });
  return selectedMap;
};

export const toggleSelected = (selectedMap: SelectedIDsMap, id: string): SelectedIDsMap => {
  const val = selectedMap[id];
  return {
    ...selectedMap,
    [id]: !val,
  };
}