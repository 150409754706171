import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './Gifficon.scss';

export type GIFFICON_TYPES = 'van';

export type GIFFICON_SIZES = 'small' | 'medium';

export interface LoadingGifProps {
  type: GIFFICON_TYPES;
  size?: GIFFICON_SIZES;
  altText?: string;
  centered?: boolean;
}

const getGifSrc = (type: GIFFICON_TYPES) => {
  switch (type) {
    case 'van':
      return '/img/van.gif';
    default:
      break;
  }
};

export const Gifficon: FunctionComponent<LoadingGifProps> = ({
  type = 'van',
  size = 'medium',
  altText = 'An animated icon',
  centered = false,
}) => {
  return (
    <img
      className={classNames(`gifficon--${size}`, {
        'gifficon--centered': centered,
      })}
      src={getGifSrc(type)}
      alt={altText}
    />
  );
};
