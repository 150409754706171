import { Button, Input, Checkbox } from '@crowdriff/flock';
import * as React from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { History, LocationState } from "history";
import { LoaderComponent, Modal, ErrorBanner } from '../components';
import { CTA } from '../components/CTA/CTA.component';
import { ConfigState } from '../config/config.types';
import { isValidEmail } from './deps/email.utils';
import { terms } from './deps/terms';
import './Email.scss';

interface EmailProps {
  email: string;
  errorMsg: string;
  config: ConfigState;
  galleryHash: string;
  translate: TranslateFunction;
  changeEmail: (val: string) => void;
  openModal: () => void;
  isLoading:boolean;
  closeModal: any;
  history: History<LocationState>;
}

export const Email: React.FC<EmailProps> = ({
  email,
  errorMsg,
  changeEmail,
  config,
  translate,
  openModal,
  isLoading,
  closeModal,
  history,
}) => {
  const {brandColor, brandImage, label} = config;
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = React.useState(false);
  const [privacyPolicyError, setPrivacyPolicyError] = React.useState('');
  const [termsChecked, setTermsChecked] = React.useState(false);
  const [termsError, setTermsError] = React.useState('');
  const [emailError, setEmailError] = React.useState('');

  const onSubmitClick = () => {
    if (!privacyPolicyChecked)
      setPrivacyPolicyError('email.privacyPolicyRequired');
    else setPrivacyPolicyError('');
    if (!termsChecked) setTermsError('email.termsRequired');
    else setTermsError('');
    if (!email) setEmailError('email.emailRequired');
    else if (!isValidEmail(email)) setEmailError('email.invalidEmail');
    else setEmailError('');
    if (termsChecked && privacyPolicyChecked && email && isValidEmail(email))
      history.push('./permissions-info');
  };

  const useOutsideAlerter = (ref: any) => {
    React.useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="emailPage">
      <Modal wrapperRef={wrapperRef}>
        <ReactMarkdown source={terms} />
      </Modal>
      <div className="brandLogoContainer">
        {brandImage && (
          <img
            className="brandLogoContainer__brandLogo"
            src={brandImage}
            alt={`${label} Brand Logo`}
          />
        )}
      </div>
      <h1 className="title">
        <Translate id="landingPage.title" />
      </h1>
      <p className="description">
        <Translate id="email.subTitle" />
      </p>

      <div className="emailPage__promptContainer">
        {emailError && <ErrorBanner errorMessage={translate(emailError)} />}
        <div className="emailPage__requiredLabel">
          <Translate id="email.required" />
        </div>
        <Input
          onChange={changeEmail}
          label={translate('email.inputLabel')}
          error={errorMsg ? translate(errorMsg) : ''}
          value={email}
          isRequired={true}
          type="email"
          className="emailPrompt"
        />
        <p>
          <Translate id="email.disconnected" />
        </p>
      </div>
      <LoaderComponent size="medium" isLoading={isLoading} />
      {privacyPolicyError && (
        <ErrorBanner errorMessage={translate(privacyPolicyError)} />
      )}
      {termsError && <ErrorBanner errorMessage={translate(termsError)} />}

      <Checkbox
        className="emailPage__checkbox"
        label={translate('email.privacyPolicy')}
        onChange={() => {
          setPrivacyPolicyChecked(!privacyPolicyChecked);
        }}
        value={privacyPolicyChecked}
        name="privacyPolicyChecked"
      />

      <Checkbox
        className="emailPage__checkbox"
        label={
          <React.Fragment>
            <Translate id="email.terms" />
            <Button buttonStyle="link" color="blue" onClick={openModal}>
              <Translate id="email.conditions" />
            </Button>
            *
          </React.Fragment>
        }
        onChange={() => {
          setTermsChecked(!termsChecked);
        }}
        value={termsChecked}
        name="termsAndConditionsChecked"
      />
      <div className="emailPage__callToActionContainer">
        <CTA
          callToAction={onSubmitClick}
          callToActionLabel={translate("email.cta")}
          brandColor={brandColor}
        />
      </div>
    </div>
  );
};
