import { ConfigState, GET_CONFIG, GetConfigActions } from './config.types';

const initialState: ConfigState = {
  brandImage: '',
  brandColor: '',
  slug: '',
  id: '',
  label: '',
};

export const configReducer = (state = initialState, action: GetConfigActions) => {
  switch (action.type) {
    case GET_CONFIG.SUCCESS:
      return {
        ...state,
        ...action.payload.config,
      };
    default:
      return state;
  }
};
