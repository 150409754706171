import isEmpty from 'ramda/es/isEmpty';
import { AppState } from '../../types';

export const selectUnconnectedInstagramAccounts = (state: AppState) =>
  state.selectAccount.instagramAccounts.filter(account => !account.isOwned);

export const selectFacebookPages = (state: AppState) =>
  state.selectAccount.facebookPages;

export const selectShowAccountList = (state: AppState) => {
  const { hasSubmittedSearch, instagramAccounts } = state.selectAccount;
  return (
    hasSubmittedSearch && instagramAccounts.length > 0 && !selectHasConnectedAllAccounts(state)
  );
};

export const selectFacebookHref = (state: AppState) => {
  return isEmpty(state.selectAccount.facebookHref)
    ? 'https://www.facebook.com'
    : state.selectAccount.facebookHref;
};

export const selectShowHasNoAccounts = (state: AppState) => {
  const { hasSubmittedSearch, instagramAccounts, facebookPages } = state.selectAccount;
  return hasSubmittedSearch && instagramAccounts.length === 0 && facebookPages.length === 0;
};

export const selectShowHasConnectedAllAccounts = (state: AppState) => {
  const { hasSubmittedSearch, instagramAccounts } = state.selectAccount;
  return hasSubmittedSearch && instagramAccounts.length > 0 && selectHasConnectedAllAccounts(state);
};

export const selectHasConnectedAllAccounts = (state: AppState) =>
  state.selectAccount.instagramAccounts.every(account => account.isOwned);

export const getErrorMessageKey = (state: AppState) => {
  if (selectShowHasNoAccounts(state)) {
    return 'errors.noAccounts';
  }

  if (selectShowHasConnectedAllAccounts(state)) {
    return 'errors.allAccountsConnected';
  }

  return 'errors.general';
};
