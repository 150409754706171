import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import renderLoader from './renderLoader';
import { appLoaded } from '../app/deps/App.actions';
import { startLoader } from '../components/Loader/deps/loader.actions';
import { LOADER_IDS } from '../components/Loader/deps/loader.types';
import { AppState } from '../types';


const mapStateToProps = (state: AppState) => {
  return { 
    isLoading: state.loader.CONFIG.isLoading, 
    config: state.config
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadApp: () => {
    dispatch(appLoaded())
    dispatch(startLoader(LOADER_IDS.CONFIG))
  }
});

export const renderLoaderConnect = compose(
  connect(mapStateToProps, mapDispatchToProps),
  renderLoader,
);
