import { ExpansionPanel } from '@crowdriff/flock';
import React, { useEffect } from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { History, LocationState } from "history";
import { ConfigState } from '../config/config.types';
import { CTA } from '../components/CTA/CTA.component';
import '../auth/LandingPage/LandingPage.scss';

export interface PermissionsInfoProps {
  config: ConfigState;
  onAuthClick: () => void;
  hasValidEmail: boolean;
  history: History<LocationState>
  translate: TranslateFunction;
}

export const PermissionsInfo = ({
  config,
  onAuthClick,
  hasValidEmail,
  history,
  translate,
}: PermissionsInfoProps): React.ReactElement => {
  // If we hit the info route without a valid email in the store, redirect to the email page
  useEffect(() => {
    if (!hasValidEmail) {
      history.push('./email')
    }
  }, [history, hasValidEmail])
  return (
      <div>
        <div className="brandLogoContainer">
          {config.brandImage && (
            <img
              className="brandLogoContainer__brandLogo"
              src={config.brandImage}
              alt={`${config.label} Brand Logo`}
            />
          )}
        </div>
        <h1 className="title">
          <Translate id="info.title" />
        </h1>
        <p className="description">
          <Translate id="info.description" data={{ DMO: config.label }} />
        </p>
        <section className="faq">
          <ExpansionPanel
            className="faq__expansionPanel"
            label={translate('info.FAQ1')}
            collapseIconType="minus"
          >
            <p>
              <Translate id="info.FAQ1-1" />
            </p>
          </ExpansionPanel>
          <ExpansionPanel
            className="faq__expansionPanel"
            label={translate('info.FAQ2')}
            collapseIconType="minus"
          >
            <ul>
              <p>
                <li>
                  <Translate id="info.FAQ2-1" />
                </li>
              </p>
              <p>
                <li>
                  <Translate id="info.FAQ2-2" />
                </li>
              </p>
            </ul>
          </ExpansionPanel>
        </section>
        <div className="callToActionContainer">
          <CTA
            callToAction={onAuthClick}
            callToActionLabel={translate("info.cta")}
            brandColor={config.brandColor}
          />
        </div>
      </div>
    );
  };
