import { find, isNil, pathOr } from 'ramda';
import { matchPath } from 'react-router';
import { AppState, ROUTES } from '../types';

export const selectSlug = (state: AppState): string | undefined => {
  const pathName = state.router.location.pathname;
  const matchArray = Object.values(ROUTES)
    .map((route: string) => {
      return matchPath(pathName, {path: route, exact: true});
    })
  const match = find((m: {} | null) => !isNil(m))(matchArray) || {};
  return pathOr(undefined, ['params', 'slug'], match);
};

export const selectQueryParam = (param: string) => (state: AppState) => {
  const queryString = new URLSearchParams(state.router.location.search);
  return queryString.get(param) || '';
};

export const isUserLocationAtRoute = (route: ROUTES) => (state: AppState): boolean => {
  const match = matchPath(state.router.location.pathname, route) || {};
  return pathOr(undefined, ['path'], match) === route;
};

export const getRoute = (state: AppState): ROUTES => {
  const pathName = state.router.location.pathname;
  const matchArray = Object.values(ROUTES)
    .map((route: string) => {
      return matchPath(pathName, route);
    })
    .reverse();
  const match = find((m: {} | null) => !isNil(m))(matchArray) || {};
  return pathOr(ROUTES.DEFAULT, ['path'], match);
};
