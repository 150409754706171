import * as React from 'react';
import './Card.scss';
interface CardProps {
  children?: {};
  className?: string;
}
export const Card: React.SFC<CardProps> = ({ children, className = '' }) => {
  return <div className={`card-component ${className}`}>{children}</div>;
};

export default Card;
