export enum GET_ACCOUNT_INFO_TYPES {
  START = 'GET_ACCOUNT_INFO/START',
  SUCCESS = 'GET_ACCOUNT_INFO/SUCCESS',
  ERROR = 'GET_ACCOUNT_INFO/ERROR',
}

export interface GetAccountInfoStartAction {
  type: typeof GET_ACCOUNT_INFO_TYPES.START;
  payload: {};
}

export interface GetAccountInfoSuccessAction {
  type: typeof GET_ACCOUNT_INFO_TYPES.SUCCESS;
  payload: {
    instagramAccounts: InstagramAccount[];
    facebookName: string;
    facebookHref: string;
    facebookAvatar: string;
    userToken: string;
  };
}

export interface GetAccountInfoErrorAction {
  type: typeof GET_ACCOUNT_INFO_TYPES.ERROR;
  payload: {
    errors: string[];
  };
}

export enum SELECT_ACCOUNT_TYPES {
  SUBMIT = 'SELECT_ACCOUNT/SUBMIT',
}

export interface SelectAccountSubmitAction {
  type: typeof SELECT_ACCOUNT_TYPES.SUBMIT;
  payload: {
    selectedInstagramAccounts: {
      [id: string]: boolean;
    };
    selectedFacebookPages: {
      [id: string]: boolean;
    };
  };
}

export enum SELECT_ACCOUNTS_REQUEST_TYPES {
  START = 'SELECT_ACCOUNT_REQUEST/START',
  SUCCESS = 'SELECT_ACCOUNT_REQUEST/SUCCESS',
  ERROR = 'SELECT_ACCOUNT_REQUEST/ERROR',
}

export interface SelectAccountsRequestStartAction {
  type: typeof SELECT_ACCOUNTS_REQUEST_TYPES.START;
  payload: {};
}

export interface SelectAccountsRequestSuccessAction {
  type: typeof SELECT_ACCOUNTS_REQUEST_TYPES.SUCCESS;
  payload: {};
}

export interface SelectAccountsRequestErrorAction {
  type: typeof SELECT_ACCOUNTS_REQUEST_TYPES.ERROR;
  payload: {};
}

export interface HasErrorMessages {
  showHasNoAccounts: boolean;
  showHasConnectedAllAccounts: boolean;
}

export type SelectAccountActions =
  | SelectAccountSubmitAction
  | SelectAccountsRequestStartAction
  | SelectAccountsRequestSuccessAction
  | SelectAccountsRequestErrorAction
  | GetAccountInfoStartAction
  | GetAccountInfoSuccessAction
  | GetAccountInfoErrorAction;

export interface InstagramAccount {
  facebookAccessToken: string;
  facebookPageId: string;
  id: string;
  isOwned: boolean;
  profilePicture: string;
  screenName: string;
  userName: string;
}

export interface FacebookPage {
  accessToken: string;
  id: string;
  name: string;
  userName: string;
}

export interface SelectedIDsMap {
  [id: string]: boolean;
}

export interface SelectAccountState {
  errors: string[];
  facebookName: string;
  facebookHref: string;
  facebookAvatar: string;
  hasSubmittedSearch: boolean;
  instagramAccounts: InstagramAccount[];
  facebookPages: FacebookPage[];
  selectedAccounts: string[];
  userToken: string;
}

export interface ConnectInstagramAccountPayload extends InstagramAccount {
  userToken: string,
}
