import * as React from 'react';
import './BodyText.scss';
interface BodyTextProps {
  children: {};
  className?: string;
}
export const BodyText: React.SFC<BodyTextProps> = ({ children, className }) => {
  return <div className={`body-text-component ${className ? className : ''}`}>{children}</div>;
};

export default BodyText;
