import { AppActions } from '../../types';
import { EMAIL_TYPES, EmailState } from './email.types';

const initialState: EmailState = {
  email: '',
  errorMsg: '',
};

export const emailReducer = (state: EmailState = initialState, action: AppActions) => {
  switch (action.type) {
    case EMAIL_TYPES.CHANGE_EMAIL:
      return {
        ...state,
        ...action.payload,
        errorMsg: '',
      };
    case EMAIL_TYPES.EMAIL_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
