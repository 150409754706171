import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { localizeReducer } from 'react-localize-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { configReducer } from './config/config.reducer';
import { emailReducer } from './email/deps/email.reducer';
import { selectAccountReducer } from './select-account/deps/SelectAccount.reducer';
import { loaderReducer  } from './components/Loader/deps/loader.reducer';
import { modalReducer } from './components/Modal/deps/modal.reducer';
import { appEpics } from './app/deps/App.epics';
import { authEpics } from './auth/deps/auth.epics';
import { configEpics } from './config/config.epics';
import { emailEpics} from './email/deps/email.epics';
import { selectAccountEpics} from './select-account/deps/SelectAccounts.epics';


const epicMiddleware = createEpicMiddleware();

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  config: configReducer,
  email: emailReducer,
  loader: loaderReducer,
  localize: localizeReducer,
  modal: modalReducer,
  router: connectRouter(history),
  selectAccount: selectAccountReducer,
});


const rootEpic = combineEpics(
  ...appEpics,
  ...authEpics,
  ...configEpics,
  ...emailEpics,
  ...selectAccountEpics,
);

const middlewareEnhancer = compose(applyMiddleware(routerMiddleware(history), epicMiddleware));

const enhancers = composeWithDevTools(middlewareEnhancer);

export const store = createStore(rootReducer, enhancers);

epicMiddleware.run(rootEpic as any); // work around for redux and redux-observable generic typings issue
