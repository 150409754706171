import * as React from 'react';
import { LocalizeContextProps } from 'react-localize-redux';
import { Route, Switch } from 'react-router-dom';
import { copyUtils } from '../copy';
import { ROUTES } from '../types';
import componentDemo from '../components/componentDemo/componentDemo';
import { ConfigState } from '../config/config.types';
import fr from '../copy/languages/fr';
import { ErrorMessage } from '../error';
import { NotBusiness } from '../not-business/NotBusiness.component';
import { Auth } from '../auth';
import { Email } from '../email';
import { PermissionsInfo } from '../permissions-info';
import { Done } from '../done/Done.component';
import { SelectAccount } from '../select-account';
import { displayGallery } from '../auth/deps/utils';
import { SidePanel } from '../side-panel';
import './App.scss';
import '../styles/app.scss';

const { getLanguage, initializePayload } = copyUtils;
interface AppProps extends LocalizeContextProps {
  appLoaded: () => {};
  config: ConfigState;
  galleryHash: string;
  isLoading: boolean;
}

const useScript = (id: string, url: string) => {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.id = id;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [id, url]);
};

export const App: React.FunctionComponent<AppProps> = (props) => {
  React.useEffect(() => {
    const language = getLanguage(window.navigator);
    props.initialize(initializePayload(language));
    if (process.env.NODE_ENV === 'production' && language === 'fr') {
      props.addTranslationForLanguage(fr, 'fr');
    }
    props.appLoaded();
  }, []);

  const { galleryHash, slug } = props.config;
  const { hash, url } = slug ? displayGallery(galleryHash) : { hash: '', url: '' };
  useScript(`cr-init__${hash}`, url);
  return (
    <div>
      <div className="page-layout">
        <main className="page-layout__content">
        <React.Suspense fallback=''>
          <Switch>
            <Route path={ROUTES.COMPONENT_DEMO} exact={true} component={componentDemo} />
            <Route path={ROUTES.AUTH} exact={true} component={Auth} />
            <Route path={ROUTES.NOT_BUSINESS} exact={true} component={NotBusiness} />
            <Route path={ROUTES.EMAIL} exact={true} component={Email} />
            <Route path={ROUTES.PERMISSIONS_INFO} exact={true} component={PermissionsInfo} />
            <Route
              path={ROUTES.DONE}
              exact={true}
              component={() =><Done config={props.config} galleryHash={galleryHash}/>}
            />
            <Route path={ROUTES.SELECT_ACCOUNT} exact={true} component={SelectAccount} />
            <Route path={ROUTES.DEFAULT} exact={true} component={ErrorMessage} />
            <Route path={ROUTES.SLUG} exact={true} component={ErrorMessage} />
          </Switch>
        </React.Suspense>
        </main>
        <div className="page-layout__sidePanel">
          <SidePanel hash={hash} />
        </div>
      </div>
    </div>
  );
};
