export enum AUTH_TYPES {
  APP_LOADED = 'AUTH_TYPES/APP_LOADED',
  AUTHENTICATE_ACCOUNT = 'AUTH_TYPES/AUTHENTICATE_ACCOUNT',
}

export interface AuthAccountAction {
  type: AUTH_TYPES.AUTHENTICATE_ACCOUNT;
}

export type AuthActions = AuthAccountAction;
export interface AuthState {
  hasAcceptedTerms: boolean;
}

export const DEFAULT_GALLERY = "ac5c0627708126c6";
export const PROD_URL = "https://starling.crowdriff.com/js/crowdriff.js";
export const STAGING_URL = "https://starling.derp.website/js/crowdriff.js";
