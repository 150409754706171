export enum GET_CONFIG {
  START = 'GET_CONFIG/START',
  SUCCESS = 'GET_CONFIG/SUCCESS',
  ERROR = 'GET_CONFIG/ERROR',
}

export interface GetConfigStart {
  type: GET_CONFIG.START;
  payload: {};
}

export interface GetConfigSuccess {
  type: GET_CONFIG.SUCCESS;
  payload: {
    config: {};
  };
}

export interface ConfigState {
  brandImage: string;
  brandColor: string;
  slug: string;
  id: string;
  label: string;
  galleryHash?: string;
}

export interface GetConfigError {
  type: GET_CONFIG.ERROR;
  payload: {};
}
export type GetConfigActions = GetConfigStart | GetConfigSuccess | GetConfigError;
