import React from 'react';
import './errorBanner.scss';

interface ErrorBannerProps {
  errorMessage?: React.ReactNode;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = ({ errorMessage }) => (
  <div className="errorBanner">
    <p>{errorMessage}</p>
  </div>
);
