import { push } from 'connected-react-router';
import { pathOr } from 'ramda';
import { matchPath } from 'react-router-dom';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LOAD_APP } from '../app/deps/App.types';
import { stopLoader } from '../components/Loader/deps/loader.actions';
import { LOADER_IDS } from '../components/Loader/deps/loader.types';
import { AppActions, AppState, ROUTES } from '../types';
import * as fetch from '../utils/fetch';
import { requestSequence } from '../utils/request-sequence';
import { GET_CONFIG } from './config.types';

export const getConfig = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(LOAD_APP.SUCCESS),
    requestSequence(GET_CONFIG, () => {
      const match = matchPath(state$.value.router.location.pathname, ROUTES.SLUG);
      const slug = pathOr('', ['params', 'slug'], match);
      return fetch.get(`/api/v2/config/${slug}`);
    }),
  );

export const handleConfigSuccessEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(GET_CONFIG.SUCCESS),
    map(() => stopLoader(LOADER_IDS.CONFIG)),
  );
export const handleConfigErrorEpic = (
  action$: ActionsObservable<AppActions>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(GET_CONFIG.ERROR),
    mergeMap(() => {
      return from([push(ROUTES.DEFAULT), stopLoader(LOADER_IDS.CONFIG)]);
    }),
  );

export const configEpics = [handleConfigSuccessEpic, handleConfigErrorEpic, getConfig];
