import { TranslateFunction } from 'react-localize-redux';
import { AppState } from '../../types';
import { ConfigState } from '../../config/config.types';
import { AuthAccountAction } from '../../auth/deps/auth.types';

export enum EMAIL_TYPES {
  CHANGE_EMAIL = 'EMAIL_TYPES/CHANGE_EMAIL',
  SEND_EMAIL = 'EMAIL_TYPES/SEND_EMAIL',
  EMAIL_ERROR = 'EMAIL_TYPES/EMAIL_ERROR',
}

export enum UPDATE_EMAIL {
  START = 'UPDATE_EMAIL/START',
  SUCCESS = 'UPDATE_EMAIL/SUCCESS',
  ERROR = 'UPDATE_EMAIL/ERROR',
}

export interface EmailErrorPayload {
  errorMsg: string;
}

export interface EmailErrorAction {
  type: EMAIL_TYPES.EMAIL_ERROR;
  payload: EmailErrorPayload;
}

export interface ChangeEmailPayload {
  email: string;
}

export interface ChangeEmailAction {
  type: EMAIL_TYPES.CHANGE_EMAIL;
  payload: ChangeEmailPayload;
}

export interface SendEmailPayload {
  email: string;
}

export interface SendEmailAction {
  type: EMAIL_TYPES.SEND_EMAIL;
  payload: SendEmailPayload;
}

export interface UpdateEmailStart {
  type: UPDATE_EMAIL.START;
}

export interface UpdateEmailSuccess {
  type: UPDATE_EMAIL.SUCCESS;
}

export interface UpdateEmailError {
  type: UPDATE_EMAIL.ERROR;
}

export interface EmailState {
  email: string;
  errorMsg: string;
}

export type EmailActions =
  | ChangeEmailAction
  | SendEmailAction
  | UpdateEmailStart
  | UpdateEmailSuccess
  | UpdateEmailError
  | EmailErrorAction;

export interface StateProps {
  email: string;
  isLoading: boolean;
  config: ConfigState;
  galleryHash?: string;
  translate: TranslateFunction,
  errorMsg: string;
  state: AppState;
}

export interface DispatchProps {
  changeEmail: (val: string) => void;
  openModal: () => void;
  closeModal: () => void;
}
