import { LOADER_IDS, LOADER_TYPES, StartLoaderAction, StopLoaderAction } from './loader.types';

export const stopLoader = (loaderId: LOADER_IDS): StopLoaderAction => ({
  type: LOADER_TYPES.STOP,
  payload: {
    id: loaderId,
  },
});

export const startLoader = (loaderId: LOADER_IDS): StartLoaderAction => ({
  type: LOADER_TYPES.START,
  payload: {
    id: loaderId,
  },
});
