import {
  LOCATION_CHANGE,
  LocationChangeAction,
  push,
} from 'connected-react-router';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isUserLocationAtRoute, selectSlug } from '../../router/router.selectors';
import { GET_ACCOUNT_INFO_TYPES } from '../../select-account/deps/SelectAccount.types';
import { AppActions, AppState, ROUTES } from '../../types';
import { pendoInitialized } from './App.actions';
import { LOAD_APP } from './App.types';

export const pendoPublicInitEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(LOAD_APP.SUCCESS),
    filter(() => isUserLocationAtRoute(ROUTES.AUTH)(state$.value)),
    map(() => {
      const slug = selectSlug(state$.value);

      (window as any).pendo.initialize({
        visitor: {
          id: slug,
        },
        account: {
          id: slug,
        },
      });
      return pendoInitialized();
    }),
  );

export const pendoPrivateInitEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(GET_ACCOUNT_INFO_TYPES.SUCCESS),
    map(() => {
      (window as any).pendo.initialize({
        visitor: {
          id: state$.value.selectAccount.facebookName,
        },
        account: {
          id: state$.value.config.id,
          slug: state$.value.config.slug,
        },
      });
      return pendoInitialized();
    }),
  );

export const redirectToDefault = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(action => {
      const locationAction = action as LocationChangeAction;
      return locationAction.payload.location.pathname === ROUTES.ROOT;
    }),
    map(() => push(ROUTES.DEFAULT)),
  );

export const appEpics = [pendoPublicInitEpic, pendoPrivateInitEpic, redirectToDefault];
