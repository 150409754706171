import { connect } from 'react-redux';
import { AppState } from '../../types';
import { Dispatch } from 'redux';
import { PermissionsInfo } from '../PermissionsInfo.component';
import { getTranslate } from 'react-localize-redux';
import { authAccount } from '../../auth/deps/auth.actions';
import { isValidEmail } from '../../email/deps/email.utils';
import { history } from '../../store';

const mapStateToProps = (state: AppState) => ({
  config: state.config,
  translate: getTranslate(state.localize),
  hasValidEmail: isValidEmail(state.email.email),
  history,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAuthClick: () => dispatch(authAccount()),
})

export const PermissionsInfoConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionsInfo);
