export enum LOADER_IDS {
  CONFIG = 'CONFIG',
  SELECT_ACCOUNTS = 'SELECT_ACCOUNTS',
  EMAIL = 'EMAIL',
}

export enum LOADER_TYPES {
  START = 'LOADER_TYPES/START',
  STOP = 'LOADER_TYPES/STOP',
}

export interface StartLoaderPayload {
  id: LOADER_IDS;
}

export interface StopLoaderAction {
  type: LOADER_TYPES.STOP;
  payload: StartLoaderPayload;
}

export interface StopLoaderPayload {
  id: LOADER_IDS;
}

export interface StartLoaderAction {
  type: LOADER_TYPES.START;
  payload: StopLoaderPayload;
}

export type LoaderState = {
  [key in keyof typeof LOADER_IDS]: {
    isLoading: boolean;
  }
};

export type LoaderActions = StopLoaderAction | StartLoaderAction;
