export enum LOAD_APP {
  SUCCESS = 'LOAD_APP/SUCCESS',
}

export enum VENDOR_TYPES {
  PENDO_INIT = 'VENDOR/PENDO_INIT',
}

export interface AppLoadSuccess {
  type: LOAD_APP.SUCCESS;
  payload: {};
}

export interface PendoInitAction {
  type: VENDOR_TYPES.PENDO_INIT;
  payload: {};
}

export type LoadAppActions = AppLoadSuccess | PendoInitAction;
