import { connect } from 'react-redux';
import { AppState } from '../../types';
import { SidePanel } from '../SidePanel.component';

type OwnProps = {
  hash: string;
};

const mapStateToProps = (state: AppState, OwnProps: OwnProps) => {
  const { pathname } = state.router.location;
  const route = pathname.substring(pathname.lastIndexOf('/') + 1);
  return {
    route,
    OwnProps,
  }
};

export const SidePanelConnect = connect(
  mapStateToProps,
)(SidePanel);
