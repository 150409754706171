import { LOAD_APP, LoadAppActions, PendoInitAction, VENDOR_TYPES } from './App.types';

export const appLoaded = (): LoadAppActions => ({
  type: LOAD_APP.SUCCESS,
  payload: {},
});

export const pendoInitialized = (): PendoInitAction => ({
  type: VENDOR_TYPES.PENDO_INIT,
  payload: {},
});
