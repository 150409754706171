import * as React from 'react';
import './Subtitle.scss';
interface SubtitleProps {
  children: {};
  className?: string;
  size?: number;
}
const defaultProps = {
  size: 16,
};

export const Subtitle: React.SFC<SubtitleProps> = ({ children, className, size }) => {
  const SubtitleStyle = {
    fontSize: size,
  };
  return (
    <div className={`subtitle-component ${className ? className : ''}`} style={SubtitleStyle}>
      {children}
    </div>
  );
};

Subtitle.defaultProps = defaultProps;

export default Subtitle;
