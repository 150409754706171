import * as React from 'react';
import './Image.scss';

import classnames from 'classnames';

interface ImageProps {
  children?: {};
  src: string;
  alt: string;
}

const initialState = {
  isErrored: false,
};
type ImageState = Readonly<typeof initialState>;

export class Image extends React.Component<ImageProps, ImageState> {
  state: ImageState = initialState;
  render() {
    const { src, children, alt } = this.props;
    const { isErrored } = this.state;
    const showFallback = typeof children !== 'undefined' && (isErrored || !src);
    return (
      <div className={classnames('image-component', { isErrored })}>
        {showFallback ? (
          children
        ) : (
          <img
            className="image"
            src={src}
            alt={alt}
            onError={() =>
              this.setState((prevState: ImageState) => ({ isErrored: true, isLoaded: true }))
            }
          />
        )}
      </div>
    );
  }
}

export default Image;
