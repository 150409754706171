import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../types';
import { SelectAccount } from '../SelectAccount.component';
import { onSelectAccountSubmit } from './SelectAccount.actions';
import { authAccount } from '../../auth/deps/auth.actions';
import {
  getErrorMessageKey,
  selectShowHasConnectedAllAccounts,
  selectShowHasNoAccounts,
  selectUnconnectedInstagramAccounts,
  selectFacebookPages,
} from './SelectAccount.selectors';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state: AppState) => {
  const showHasNoAccounts = selectShowHasNoAccounts(state);
  const showHasConnectedAllAccounts = selectShowHasConnectedAllAccounts(state);
  return {
    config: state.config,
    errorMessageKey: getErrorMessageKey(state),
    hasError: showHasNoAccounts || showHasConnectedAllAccounts,
    instagramAccounts: selectUnconnectedInstagramAccounts(state),
    facebookPages: selectFacebookPages(state),
    translate: getTranslate(state.localize),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSelectAccountSubmit: (selectedInstagramAccounts: {}, selectedFacebookPages: {}) =>
    dispatch(onSelectAccountSubmit(selectedInstagramAccounts, selectedFacebookPages)),
    onAuthClick: () => dispatch(authAccount()),
});

export const SelectAccountConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectAccount);
