import general from './general.json';
import notBusiness from './notBusiness.json'
import done from './done.json'
import SelectAccount from './selectAccount.json'
import email from './email.json';
import info from './info.json';
import errors from './errors.json'
import landingPage from './landingPage.json'

export default {
  done,
  email,
  info,
  general,
  notBusiness,
  SelectAccount,
  errors,
  landingPage
};