import find from 'ramda/es/find';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { stopLoader } from '../../components/Loader/deps/loader.actions';
import { LOADER_IDS } from '../../components/Loader/deps/loader.types';
import { pushWithStateParams } from '../../router/router.actions';
import { InstagramAccount } from '../../select-account/deps/SelectAccount.types';
import { AppActions, AppState, ROUTES } from '../../types';
import * as fetch from '../../utils/fetch';
import { requestSequence } from '../../utils/request-sequence';
import { EMAIL_TYPES, SendEmailAction, UPDATE_EMAIL } from './email.types';

export const postEmail = (action: SendEmailAction, state: AppState) => {
  const instagramAccounts = state.selectAccount.selectedAccounts.map(
    (id) => {
      const instagramAccount = find(
        (account: InstagramAccount) => account.id === id,
      )(state.selectAccount.instagramAccounts) as InstagramAccount;
      return {
        email: action.payload.email,
        facebookPageId: instagramAccount.facebookPageId,
      };
    },
  );

  return fetch.post('/api/email', {
    instagramAccounts,
  });
};

export const sendEmailEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(EMAIL_TYPES.SEND_EMAIL),
    requestSequence(UPDATE_EMAIL, (action) => postEmail(action, state$.value)),
  );

export const handleUpdateEmailEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    filter(
      (action) =>
        action.type === UPDATE_EMAIL.SUCCESS ||
        action.type === UPDATE_EMAIL.ERROR,
    ),
    mergeMap(() =>
      from([
        pushWithStateParams(state$.value, ROUTES.DONE),
        stopLoader(LOADER_IDS.EMAIL),
      ]),
    ),
  );

export const emailEpics = [
  sendEmailEpic,
  handleUpdateEmailEpic,
];
