import { AppActions } from '../../../types';
import {
  LOADER_IDS,
  LOADER_TYPES,
  LoaderState,
  StartLoaderPayload,
  StopLoaderPayload,
} from './loader.types';

const initialState: LoaderState = {
  [LOADER_IDS.CONFIG]: {
    isLoading: false,
  },
  [LOADER_IDS.SELECT_ACCOUNTS]: {
    isLoading: false,
  },
  [LOADER_IDS.EMAIL]: {
    isLoading: false,
  },
};

export const loaderReducer = (state: LoaderState = initialState, action: AppActions) => {
  switch (action.type) {
    case LOADER_TYPES.START:
      const startPayload = action.payload as StartLoaderPayload;
      return {
        ...state,
        [startPayload.id]: {
          ...state[startPayload.id],
          isLoading: true,
        },
      };
    case LOADER_TYPES.STOP:
      const stopPayload = action.payload as StopLoaderPayload;
      return {
        ...state,
        [stopPayload.id]: {
          ...state[stopPayload.id],
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
