import { Observable } from 'rxjs';

/**
 * request sequence is meant to keep your redux store up to date while performing async actions.
 * You can use this operator like other rxjs operators, by chaining it onto the action stream. You
 * will need to pass it 2 parameters:
 * @param {enum} asyncActionTypes
 * @param {string} asyncActionTypes.START - The redux action type dispatched on start
 * @param {string} asyncActionTypes.SUCCESS - The redux action type dispatched on success
 * @param {string} asyncActionTypes.ERROR - The redux action type dispatched on failure
 * and
 * @param {(action) => Promise} asyncFunc - The asynchcronous function you'd like to call
 *
 * requestSequence will dispatch asyncActionTypes.START, and either asyncActionTypes.SUCCESS
 * or asyncActionTypes.FAILURE with the associated data or error as the payload. The associated
 * payload or error is determined by the resolution of asyncFunc's returning Promise.
 */

export const requestSequence = (asyncActionTypes: any, asyncFunc: (action: any) => Promise<{}>) => (
  source: Observable<{}>,
) =>
  Observable.create((subscriber: any) => {
    let promise: any = null;
    const subscription = source.subscribe(
      actionIn => {
        try {
          subscriber.next({ type: asyncActionTypes.START });
          promise = asyncFunc(actionIn).then(
            (data: any) => {
              subscriber.next({
                type: asyncActionTypes.SUCCESS,
                payload: data,
              });
            },
            (err: any) => {
              subscriber.next({
                type: asyncActionTypes.ERROR,
                payload: err,
              });
            },
          );
        } catch (err) {
          subscriber.error(err);
        }
      },
      err => subscriber.error(err),
      () =>
        promise && promise.then ? promise.then(() => subscriber.complete()) : subscriber.complete(),
    );
    return subscription;
  });
