import * as React from 'react';
import { isEmpty } from 'ramda';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { AccountSelector } from './components/AccountSelector.component';
import { MessagePanel, MESSAGE_PANEL_TYPES } from '../components/MessagePanel/MessagePanel.component';
import { InstagramAccount, FacebookPage, SelectedIDsMap, SelectAccountSubmitAction } from './deps/SelectAccount.types';
import { getSelectedKeys, buildAllSelectedMap, toggleSelected } from './deps/SelectAccount.utils';
import './SelectAccount.scss';
import { ConfigState } from '../config/config.types';
import { CTA } from '../components/CTA/CTA.component';
import { LogoTitleSection } from './components/LogoTitleSection.component';
import { InformationSection } from './components/InformationSection.component';

interface SelectAccountProps {
  config: ConfigState;
  instagramAccounts: InstagramAccount[];
  facebookPages: FacebookPage[];
  onSelectAccountSubmit: (selectedInstagramAccounts: {}, selectedFacebookPages: {}) => SelectAccountSubmitAction;
  onAuthClick: () => void;
  hasError: boolean;
  errorMessageKey: string;
  galleryHash: string;
  translate: TranslateFunction;
}

export const SelectAccount: React.FunctionComponent<SelectAccountProps> = ({
  config,
  instagramAccounts = [],
  facebookPages = [],
  hasError,
  errorMessageKey,
  onSelectAccountSubmit,
  onAuthClick,
  translate,
}) => {
  const [selectedInstagramAccounts, setSelectedInstagramAccounts] = React.useState<SelectedIDsMap>({});
  React.useEffect(() => {
    if (isEmpty(selectedInstagramAccounts) && instagramAccounts.length > 0) setSelectedInstagramAccounts(buildAllSelectedMap(instagramAccounts));
  }, [instagramAccounts, selectedInstagramAccounts]);

  const [selectedFacebookPages, setSelectedFacebookPages] = React.useState<SelectedIDsMap>({});
  React.useEffect(() => {
    if (isEmpty(selectedFacebookPages) && facebookPages.length > 0) setSelectedFacebookPages(buildAllSelectedMap(facebookPages));
  }, [facebookPages, selectedFacebookPages]);

  const isSubmitDisabled = getSelectedKeys(selectedInstagramAccounts).length === 0 && getSelectedKeys(selectedFacebookPages).length === 0;

  return (
    <div>
      <LogoTitleSection config={config} hasError={hasError}/>
      {hasError ? (
        <MessagePanel type={MESSAGE_PANEL_TYPES.ERROR}>
          <h3>
            <Translate id="SelectAccount.connectionFailed" />
          </h3>
          <p>
            <Translate id={errorMessageKey} />
          </p>
        </MessagePanel>
      ) : (
        <>
          <AccountSelector
            titleKey="SelectAccount.igAccountsTitle"
            accounts={instagramAccounts.map((igAccount) => ({
              id: igAccount.id,
              label: igAccount.screenName || igAccount.userName,
            }))}
            selectedAccounts={selectedInstagramAccounts}
            onChange={(id) =>
              setSelectedInstagramAccounts(
                toggleSelected(selectedInstagramAccounts, id),
              )
            }
            iconType="instagram"
          />
          <AccountSelector
            titleKey="SelectAccount.fbPagesTitle"
            accounts={facebookPages.map((fbPage) => ({
              id: fbPage.id,
              label: fbPage.userName,
            }))}
            selectedAccounts={selectedFacebookPages}
            onChange={(id) =>
              setSelectedFacebookPages(
                toggleSelected(selectedFacebookPages, id),
              )
            }
            iconType="facebook"
          />
          <div className="connectAccounts__callToActionContainer">
            <CTA
              brandColor={config.brandColor}
              callToAction={() => {
                onSelectAccountSubmit(
                  selectedInstagramAccounts,
                  selectedFacebookPages,
                );
              }}
              callToActionLabel={translate('SelectAccount.addAccounts')}
              isDisabled={isSubmitDisabled}
            />
          </div>
        </>
      )}
      <InformationSection
        translate={translate}
        hasError={hasError}
        label={config.label}
      />
      {hasError && (
        <div className="retryConnecting">
          <CTA
            callToAction={onAuthClick}
            callToActionLabel={translate("SelectAccount.retryConnecting")}
            brandColor={config.brandColor}
          />
        </div>
      )}
    </div>
  );
};
