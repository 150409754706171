import { isNil } from 'ramda';

export const isValidEmail = (value: string | number): boolean => {
  if (typeof value === 'number') {
    return false;
  }

  if (isNil(value)) {
    return false;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const match = value.match(emailRegex);

  if (match === null || value !== match[0]) {
    return false;
  }
  return true;
};
