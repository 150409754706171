import { PROD_URL, STAGING_URL, DEFAULT_GALLERY } from "./auth.types"

export const displayGallery = (galleryHash: string = '') => {
  // check first if gallery hash exists. If it exists based on env show either PROD_URL or STAGING_URL
  if (galleryHash) {
    if (process.env.REACT_APP_SERVER_URL  === 'https://auth.crowdriff.com') {
      return { hash: galleryHash, url: PROD_URL }
    }
    return { hash: galleryHash, url: STAGING_URL }
  } else {
    // if no hash exists show prod gallery and prod URL
    return { hash: DEFAULT_GALLERY, url: PROD_URL }
  }
}
