import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { startLoader, stopLoader } from '../../components/Loader/deps/loader.actions';
import { LOADER_IDS } from '../../components/Loader/deps/loader.types';
import { GET_CONFIG } from '../../config/config.types';
import { pushWithStateParams } from '../../router/router.actions';
import { isUserLocationAtRoute } from '../../router/router.selectors';
import { AppActions, AppState, ROUTES } from '../../types';
import { get, post } from '../../utils/fetch';
import { requestSequence } from '../../utils/request-sequence';
import {
  GET_ACCOUNT_INFO_TYPES,
  SELECT_ACCOUNT_TYPES,
  SELECT_ACCOUNTS_REQUEST_TYPES,
  SelectAccountSubmitAction,
  SelectedIDsMap,
  FacebookPage,
  ConnectInstagramAccountPayload,
} from './SelectAccount.types';
import { getSelectedKeys } from './SelectAccount.utils';

const buildConnectIGAccountsPayload = (instagramAccountIds: SelectedIDsMap, state: AppState): ConnectInstagramAccountPayload[] => {
  const selectedInstagramAccountIds = getSelectedKeys(instagramAccountIds);
  const allConnectedInstagramAccounts = state.selectAccount.instagramAccounts;

  return selectedInstagramAccountIds.map(selectedId => {
    const selectedAccount = allConnectedInstagramAccounts.find((account => account.id === selectedId));
    return Object.assign({}, selectedAccount, { // using Object.assign instead of spread since current TS version complains
      userToken: state.selectAccount.userToken,
    });
  });
}

const buildConnectFBPagesPayload = (facebookPageIds: SelectedIDsMap, state: AppState): FacebookPage[] => {
  const selectedFacebookPageIds = getSelectedKeys(facebookPageIds);
  const allFacebookPages = state.selectAccount.facebookPages;

  return selectedFacebookPageIds.map((selectedId) => {
    const selectedFacebookPage = allFacebookPages.find((facebookPage) => facebookPage.id === selectedId);
    return Object.assign({}, selectedFacebookPage);
  });
}

export const accountInfoRequest = () => {
  return get('/api/account/').catch(e => {
    throw new Error('something went wrong');
  });
};

export const getAccountsEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(GET_CONFIG.SUCCESS),
    filter(() => isUserLocationAtRoute(ROUTES.SELECT_ACCOUNT)(state$.value)),
    requestSequence(GET_ACCOUNT_INFO_TYPES, accountInfoRequest),
  );

export const startLoadingEpic = (action$: ActionsObservable<AppActions>): Observable<AppActions> =>
  action$.pipe(
    filter(
      (action: AppActions) =>
        action.type === GET_ACCOUNT_INFO_TYPES.START ||
        action.type === SELECT_ACCOUNTS_REQUEST_TYPES.START,
    ),
    mapTo(startLoader(LOADER_IDS.SELECT_ACCOUNTS)),
  );

export const stopLoadingEpic = (action$: ActionsObservable<AppActions>): Observable<AppActions> =>
  action$.pipe(
    filter(
      (action: AppActions) =>
        action.type === GET_ACCOUNT_INFO_TYPES.SUCCESS ||
        action.type === GET_ACCOUNT_INFO_TYPES.ERROR ||
        action.type === SELECT_ACCOUNTS_REQUEST_TYPES.SUCCESS ||
        action.type === SELECT_ACCOUNTS_REQUEST_TYPES.ERROR,
    ),
    mapTo(stopLoader(LOADER_IDS.SELECT_ACCOUNTS)),
  );

export const selectAccountsRequest = (
  instagramAccountIds: SelectedIDsMap,
  facebookPageIds: SelectedIDsMap,
  state: AppState,
) => {
  return post(`/api/auth-accounts/`, {
    instagramAccounts: buildConnectIGAccountsPayload(instagramAccountIds, state),
    facebookPages: buildConnectFBPagesPayload(facebookPageIds, state),
  });
};

export const selectAccountSubmitEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(SELECT_ACCOUNT_TYPES.SUBMIT),
    requestSequence(SELECT_ACCOUNTS_REQUEST_TYPES, (action: SelectAccountSubmitAction) => {
      const { selectedInstagramAccounts, selectedFacebookPages } = action.payload;
      return selectAccountsRequest(selectedInstagramAccounts, selectedFacebookPages,  state$.value);
    }),
  );

export const handleSelectAccountSuccessEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
): Observable<AppActions> =>
  action$.pipe(
    ofType(SELECT_ACCOUNTS_REQUEST_TYPES.SUCCESS),
    map(() => pushWithStateParams(state$.value, ROUTES.DONE)),
  );

export const selectAccountEpics = [
  getAccountsEpic,
  handleSelectAccountSuccessEpic,
  selectAccountSubmitEpic,
  startLoadingEpic,
  stopLoadingEpic,
];
