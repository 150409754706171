import React, { FunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';
import { ConfigState } from '../config/config.types';
import './Done.scss';

interface DoneProps {
  config: ConfigState;
  galleryHash?: string;
}

export const Done: FunctionComponent<DoneProps> = ({ config, galleryHash }) => {
  return (
    <div className="donePage">
      <div className="contentContainer">
        <div className="brandLogoContainer">
          {config.brandImage && (
            <img
              className="brandLogoContainer__brandLogo"
              src={config.brandImage}
              alt={`${config.label}`}
            />
          )}
        </div>
        <h1 className="title">
          <Translate id="done.wereConnected" />
        </h1>
        <p className="description">
          <Translate
            id="done.thanksForConnectingWithDMO"
            data={{ DMO: config.label }}
          />
        </p>
      </div>
    </div>
  );
};
