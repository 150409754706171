import * as React from 'react';
import { ExpansionPanel } from '@crowdriff/flock';
import { Translate, TranslateFunction } from 'react-localize-redux';

interface InformationSectionProps {
  translate: TranslateFunction;
  hasError: boolean;
  label: string;
}

export const InformationSection: React.FunctionComponent<InformationSectionProps> = ({
  translate,
  hasError,
  label,
}) => {
  return (
    hasError ? (
      <ExpansionPanel
      className="permissions__expansionPanel"
      label={translate('SelectAccount.permissionHeader')}
      collapseIconType="minus"
    >
        <p><Translate id="SelectAccount.permissionSubheader" /></p>
        <ul className="permissions__list">
          <li><Translate id="SelectAccount.permission-1" /></li>
          <li><Translate id="SelectAccount.permission-2" /></li>
          <li><Translate id="SelectAccount.permission-3" /></li>
          <li><Translate id="SelectAccount.permission-4" /></li>
          <li><Translate id="SelectAccount.permission-5" data={{ DMO: label }} /></li>
          <li><Translate id="SelectAccount.permission-6" /></li>
          <li><Translate id="SelectAccount.permission-7" /></li>
        </ul>
      </ExpansionPanel>
    ) : (
      <ExpansionPanel
        className="faq__expansionPanel"
        label={translate('SelectAccount.FAQ')}
        collapseIconType="minus"
      >
        <p>
          <span className='faq__expansionPanel--titleType'>
            <Translate id="SelectAccount.FAQinstagramAccounts" />
          </span>
          <Translate id="SelectAccount.FAQinstagramAccountsDesc" />
        </p>
        <p>
          <span className='faq__expansionPanel--titleType'>
            <Translate id="SelectAccount.FAQfbPages" />
          </span>
          <Translate id="SelectAccount.FAQfbPagesDesc" />
        </p>
      </ExpansionPanel>
    )
  )
}
